import React, { Component } from 'react'

import SEO from '../components/seo'
import { Link } from 'gatsby'
import { Flex, Box } from '@rebass/grid'

import Title from '../components/page-title'
//import Copy from "../components/page-copy";
import Row from '../components/page-content-row'
import PageContact from '../components/page-contact'
import PageCtaBoxes from '../components/page-cta-boxes'
//import PageOverview from "../components/page-overview";
import Copy from '../components/page-copy'
import Image1 from '../images/etc/happyhaus-gallery-2019-05-30-photo-display-banyo-AM-01.jpg'
import BlogContainer from '../components/blog-page/PageBlog/BlogContainer'
import BlogQuoteRow from '../components/blog-page/PageBlog/BlogQuoteRow'
import styled from 'styled-components'
import { OutlineButton, theme } from '../util/style'

const ogImages = [`https://happyhaus.com.au${Image1}`]

class Page extends Component {
  render() {
    return (
      <>
        <SEO
          title="Contact"
          description="Contact our helpful team, and discover the difference in our
                homes and customer experience."
          images={ogImages}
        />
        <BlogContainer pb={['92px', '140px']} pt={['55px', '67px']}>
          <BlogQuoteRow
            data={{
              content: (
                <QuoteWrapper>
                  <QuoteWrapperInner>
                    Talk to our design + build team to discover the difference
                    in our homes and client experience.
                  </QuoteWrapperInner>
                </QuoteWrapper>
              ),
            }}
          />
        </BlogContainer>
        <BlogContainer pb={['15px', '65px']} pt={['26px']}>
          <Separator px={2}>
            <Line />
          </Separator>
          <InfoWrapper>
            <InfoBox>
              <InfoHeading>Office & Studio</InfoHeading>
              <InfoCopy>
                By Appointment
                <br />
                108 Nudgee Rd Hamilton Q 4007
                <br />
                <a href="tel:0731865771">07 3186 5771</a>
                <br />
                <a
                  href="mailto:homes@happyhaus.com.au"
                  style={{
                    borderBottom: '0 !important',
                    textDecoration: 'none',
                  }}
                >
                  <Button>homes@happyhaus.com.au</Button>
                </a>
              </InfoCopy>
            </InfoBox>
            <InfoBox>
              <InfoHeading>Work with us</InfoHeading>
              <InfoCopy>
                We're always on the lookout for talented people to join our
                in-haus design + build teams.
                <Link
                  to="/work-with-us"
                  style={{
                    borderBottom: '0 !important',
                    textDecoration: 'none',
                  }}
                >
                  <Button>Learn more</Button>
                </Link>
              </InfoCopy>
            </InfoBox>
            <InfoBox>
              <InfoHeading>Open Haus</InfoHeading>
              <InfoCopy>
                Register your interest to hear about select open homes available
                for a walk through in the future.
                <Link
                  to="/open-haus"
                  style={{
                    borderBottom: '0 !important',
                    textDecoration: 'none',
                  }}
                >
                  <Button>Learn more</Button>
                </Link>
              </InfoCopy>
            </InfoBox>
          </InfoWrapper>
        </BlogContainer>
        <PageContact submitted={false} />
      </>
    )
  }
}

export default Page

const InfoWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    gap: 45px;
  }
`

const InfoBox = styled(Box)`
  width: 77%;
  @media (max-width: 800px) {
    width: 100%;
  }
`

const InfoHeading = styled(Box)`
  font-size: 19px;
  margin-bottom: 14px;
  font-family: ${theme.font.fontFamilyBold};

  @media (max-width: 800px) {
    font-size: 19px;
  }
`
const InfoCopy = styled(Box)`
  font-size: 19px;
  font-family: ${theme.font.fontFamilyLight};
  color: ${theme.colors.grey7};

  @media (max-width: 800px) {
    font-size: 19px;
  }

  a {
    color: ${theme.colors.grey7};
  }
`

const Separator = styled(Box)`
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
`

const Line = styled(Box)`
  background-color: ${theme.colors.grey11};
  height: 1px;
`

const Button = styled(OutlineButton)`
  margin-top: 40px;
  display: block;
`

const QuoteWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 800px) {
    font-size: 23px;
  }
`

const QuoteWrapperInner = styled(Box)`
  width: 70%;
  @media (max-width: 800px) {
    width: 100%;
  }
`
