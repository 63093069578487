import React, { Component } from 'react'
import styled from 'styled-components'
import Image from '../components/page-image-new'

import { Link } from 'gatsby'

import { theme, fontBold } from '../util/style'

class PageCtaBox extends Component {
  render() {
    return (
      <Wrap to={this.props.ctaLink}>
        <ImageWrap>
          <Image src={this.props.image} />
        </ImageWrap>
        <Copy dangerouslySetInnerHTML={{ __html: this.props.copy }} />
        <Cta>{this.props.ctaText}</Cta>
      </Wrap>
    )
  }
}

export default PageCtaBox

const Cta = styled.span`
  display: block;
  color: ${theme.colors.grey1};
  width: 100%;
  line-height: 130%;
  padding-left: 15px;
  padding-right: 15px;
  ${fontBold()};
  z-index: 2;
  @media (max-width: 800px) {
    font-size: 100%;
  }
`

const Copy = styled.span`
  width: 100%;
  font-size: 125%;
  line-height: 130%;
  color: ${theme.colors.grey1};
  text-align: left;
  display: block;
  margin-bottom: 1em;
  padding-left: 15px;
  padding-right: 15px;
  z-index: 2;
  @media (max-width: 800px) {
    top: 24px;
    font-size: 100%;
    padding: 50;
  }
`

const ImageWrap = styled.span`
  display: block;
  width: 100%;
  position: relative;
  /* padding-top: ${(1080 / 1620) * 100}%; */
  margin-bottom: 1em;
   /* {
    position: absolute;
    display: block;
    left: 0px;
    top: 0px;
  } */
`

const Wrap = styled(Link)`
  background: ${theme.colors.white};
  border-left: 1px solid ${theme.colors.grey2};
  padding-left: 0px;
  padding-right: 0px;
  align-content: flex-start;
  width: 25%;
  height: 360px;
  display: flex;
  flex-wrap: wrap;
  transition: background 0.1s ease-in;

  @media (max-width: 800px) {
    padding-top: 0%;
    width: 50%;
    height: 380px;
  }

  @media (max-width: 500px) {
    padding-top: 0%;
    width: 100%;
    height: auto;
    padding-bottom: 12px;
  }

  @media (max-width: 375px) {
    padding-top: 0%;
    width: 100%;
  }
  &:hover {
    background: ${theme.colors.grey6};
  }
`
