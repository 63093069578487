import { Box, Flex } from '@rebass/grid'
import React, { Component } from 'react'

import BgImg from '../images/etc/happyhaus-form-bg.jpg'
import ContactForm from './form-contact'
import Copy from '../components/page-copy'
import Title from '../components/page-title'
import styled from 'styled-components'
import { theme } from '../util/style'

export default class PageContact extends Component {
  _getContactForm() {
    const { submitted, bg } = this.props
    let content
    if (submitted) {
      content = (
        <FormWrap bg={bg}>
          <FormBox>
            <CopyBox>
              <Box px={'20px'}>
                <Title align="center">You’ve got mail.</Title>
              </Box>
              <Copy align="center">
                <p>
                  Please check your inbox, spam or junk folder to open our
                  confirmation email to ensure you receive future updates.
                </p>
              </Copy>
            </CopyBox>
          </FormBox>
        </FormWrap>
      )
    } else {
      content = (
        <FormWrap bg={bg}>
          <FormBox>
            <FormTitle style={{ fontFamily: theme.font.fontFamilySerifThin }}>
              Let’s talk
            </FormTitle>
            <ContactForm category="Contact" redirect="/contact-thank-you" />
          </FormBox>
        </FormWrap>
      )
    }
    return content
  }

  render() {
    const content = this._getContactForm()

    return (
      <Wrap>
        <Flex>
          <Box width={1} px={2}>
            {content}
          </Box>
        </Flex>
      </Wrap>
    )
  }
}

const Wrap = styled.div`
  margin-top: 85px;
  margin-bottom: 85px;
`

const FormWrap = styled.div`
  background: url(${BgImg}) no-repeat center center;

  background-size: cover;
  padding: 100px 200px 100px 200px;
  @media (max-width: 800px) {
    padding: 0px;
  }
`

const FormBox = styled.div`
  background: ${theme.colors.white};
  width: 100%;
  margin: 0 auto;
  @media (max-width: 800px) {
    background: ${theme.colors.grey4};
  }
`

const CopyBox = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-bottom: 30px;
  padding-top: 30px;
`

const FormTitle = styled.div`
  padding: 50px 0;
  font-size: 180%;
  color: ${theme.colors.grey1};
  text-align: center;
`
