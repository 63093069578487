import React, { Component } from 'react'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'
import CtaBox from './page-cta-box'
import { theme } from '../util/style'

const Image1 =
  'resources/happyhaus-gallery-2019-07-24-photo-strong-knockdown-1640.jpg'

const Image2 =
  'resources/happyhaus-gallery-2021-09-10-photo-annerley-JG-1050120.jpg'

const Image3 =
  'resources/happyhaus-gallery-2022-03-23-photo-grant-3-JG-1020920.jpg'

const Image4 = 'resources/happyhaus-gallery-photo-dewar-AM-5.jpg'

class PageCtaBoxes extends Component {
  render() {
    return (
      <Wrap>
        <Flex flexWrap="wrap">
          <Box width={[1]} px={2}>
            <Inner>
              <CtaBox
                copy="Resources<br />& Guides"
                ctaText="Learn"
                ctaLink="/resources"
                image={Image1}
              />
              <CtaBox
                copy="New Haus<br />Gallery"
                ctaText="View"
                ctaLink="/gallery"
                image={Image2}
              />
              <CtaBox
                copy="Design<br />Consults"
                ctaText="Book"
                ctaLink="/together"
                image={Image3}
              />
              <CtaBox
                copy="3D Haus<br />Tours"
                ctaText="View"
                ctaLink="/display-haus"
                image={Image4}
              />
              <BorderRight />
            </Inner>
          </Box>
        </Flex>
      </Wrap>
    )
  }
}

export default PageCtaBoxes

const Wrap = styled.div`
  margin-bottom: 85px;
  margin-top: 50px;
  img {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
`

const Inner = styled.div`
  display: flex;
  flex-wrap: nowrap;

  @media (max-width: 800px) {
    flex-wrap: wrap;
    top: 24px;
  }
`

const BorderRight = styled.div`
  border-right: 1px solid ${theme.colors.grey2};
`
