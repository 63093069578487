import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Image = ({ src, alt }) => {
  const findImage = (data) => {
    const { edges } = data.allFile

    const foundImages = edges.filter((edge) => {
      return edge.node.relativePath === src
    })

    if (foundImages.length > 0) {
      return foundImages[0]
    } else {
      return null
    }
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          allFile {
            edges {
              node {
                relativePath
                childImageSharp {
                  fluid(maxWidth: 1300, quality: 80) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <RenderImage imageNode={findImage(data)} alt={alt} />}
    />
  )
}
export default Image

const RenderImage = ({ imageNode, alt }) => {
  if (imageNode === null) return null

  const altText = alt ? alt : ''

  return <Img fluid={imageNode.node.childImageSharp.fluid} alt={altText} />
}
